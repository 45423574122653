import styled from '@emotion/styled';
import { switchProp } from 'styled-tools';
import { linearBackground } from '~/shared/utils/styled';
import { TextBlockForContentCardsLayout } from './TextBlockForContentCards';

export const StyledTextBlockForContentCards = styled.div<{
    position: TextBlockForContentCardsLayout;
    backgroundColor?: string;
}>(
    ({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: theme.spaces[2],
        whiteSpace: 'pre-line',
    }),
    switchProp('position', {
        on: ({ theme, backgroundColor }) => ({
            ...linearBackground(backgroundColor),
            inset: 'auto 0 0 0',
            padding: `${theme.spaces[5]} ${theme.spaces[3]} ${theme.spaces[3]}`,
        }),
        below: ({ theme, backgroundColor }) => ({
            backgroundColor,
            minHeight: theme.sizes.contentCardMinHeight,
            padding: `${theme.spaces[3]} ${theme.spaces[5]}`,
        }),
    })
);

export const StyledHeadlineArea = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
});

export const StyledTexts = styled.div({
    display: 'flex',
    flexDirection: 'column',
});
