import React, { useEffect, useState } from 'react';
import { ImageAspectRatio, StyledCategory, StyledImageContainer, StyledLink } from './styled';
import {
    TextBlockForContentCards,
    TextBlockForContentCardsLayout,
} from '../TextBlockForContentCards';
import { ImageMedia, Link as LinkType, VideoMedia } from '~/lib/data-contract';
import { ConditionalWrap } from '~/shared/utils/jsx';
import { LegacyImage, ImageProps } from '~/shared/components';
import { useMedia } from 'react-use';
import { queries, queriesIsolated, useThemeShade } from '~/theme';
import { useTheme } from '@emotion/react';
import { useModule } from '~/templates';
import { M11HeroVideo } from '$templates/blocks/components/M11Hero/M11HeroVideo';
import { useCookieConsent } from '$shared/hooks';

type Props = Partial<{
    image: ImageMedia;
    callToAction: LinkType;
    backgroundColor: string;
    headline: string;
    description: string;
    imagesVW: number;
    /**
     * Will stretch to outer container instead of using a aspect ratio
     */
    stretch: boolean;
    /**
     * Text can be placed "on" or "below the category card"
     */
    textPosition: TextBlockForContentCardsLayout;
    as?: React.ElementType;
    video?: VideoMedia;
}> & { imageAspectRatio: ImageAspectRatio } & Pick<ImageProps, 'hoverStyle'>;

export const Category = ({
    imageAspectRatio,
    image,
    callToAction,
    backgroundColor: initialBackgroundColor,
    headline,
    description,
    imagesVW = 100,
    stretch = false,
    textPosition,
    hoverStyle = 'both',
    video,
    ...rest
}: Props) => {
    const isMobile = !useMedia(queries.md, false);
    const { traits } = useTheme();
    const moduleTheme = useThemeShade({ backgroundColor: initialBackgroundColor });
    const { index } = useModule();
    const position = (textPosition ||
        traits.contentCard.textPosition) as TextBlockForContentCardsLayout;

    const { marketing, statistic } = useCookieConsent();
    const [mediaType, setMediaType] = useState<'video' | 'image'>(
        video?.src && marketing && statistic ? 'video' : 'image'
    );

    useEffect(() => {
        setMediaType(video?.src && marketing && statistic ? 'video' : 'image');
    }, [marketing, statistic, video, setMediaType]);

    return (
        <StyledCategory {...rest}>
            <ConditionalWrap
                if={Boolean(callToAction?.url)}
                wrap={(categoryContent) => (
                    <StyledLink
                        href={callToAction?.url || ''}
                        title={callToAction?.title}
                        target={callToAction?.target}
                        children={categoryContent}
                        type="router"
                    />
                )}
            >
                {mediaType === 'image' && image?.src ? (
                    <StyledImageContainer {...imageAspectRatio} stretch={stretch}>
                        <LegacyImage
                            {...image}
                            src={image.src}
                            alt={image.alt}
                            layout="fill"
                            objectFit="cover"
                            sizes={`${queriesIsolated.xs} 100vw, ${queriesIsolated.sm} 100vw, ${imagesVW}vw`}
                            skeletonShade="none"
                            onLoadAnimation={index === 0 ? 'none' : 'swipe'}
                            cW={imageAspectRatio[isMobile ? 'mobile' : 'desktop'][0]}
                            cH={imageAspectRatio[isMobile ? 'mobile' : 'desktop'][1]}
                            hoverStyle={hoverStyle}
                        />
                    </StyledImageContainer>
                ) : null}
                {mediaType === 'video' ? (
                    <StyledImageContainer {...imageAspectRatio} stretch={stretch}>
                        <M11HeroVideo
                            {...{
                                video: { ...video, controls: false },
                                hasTextContent: true,
                                imageSrc: image?.src,
                                callToAction: callToAction,
                            }}
                        />
                    </StyledImageContainer>
                ) : null}
            </ConditionalWrap>
            <TextBlockForContentCards
                headline={headline}
                moduleTheme={{
                    ...moduleTheme,
                    backgroundColor: initialBackgroundColor,
                }}
                text={description}
                textPosition={position}
                callToAction={callToAction}
            />
        </StyledCategory>
    );
};
