import styled from '@emotion/styled';
import { ifNotProp, ifProp } from 'styled-tools';
import { Link } from '~/shared/components/Link';
import { aspectRatio } from '~/shared/utils/styled';
import { breakpoints } from '~/theme';

export type ImageAspectRatio = { mobile: [number, number]; desktop: [number, number] };
export const StyledCategory = styled.div({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    height: '100%',
});

export const StyledImageContainer = styled.div<ImageAspectRatio & { stretch?: boolean }>(
    {
        position: 'relative',
        width: '100%',
    },
    ifProp('stretch', {
        flex: 1,
        height: '100%',
    }),
    ifNotProp('stretch', ({ mobile, desktop }) => ({
        ...aspectRatio(mobile[0], mobile[1]),

        [breakpoints.md]: {
            ...aspectRatio(desktop[0], desktop[1]),
        },
    }))
);

export const StyledLink = styled(Link)({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
});
